<template>
  <v-container fluid class="ma-0 pa-0">
    <v-layout row wrap class="clear-background">

        <v-layout class="primary-background" pa-4>
          <div class="max-width-1200 ma-auto">
            <v-layout row wrap ma-8>
              <v-flex offset-sm1 sm6 mt-12 v-if="$vuetify.breakpoint.smAndUp">
                  <v-img :src="driveop" :lazy-src="driveop" @click="mission_text = !mission_text" style="width: 400px;"></v-img>
                  <p class="antenna font-16 secondary-color underline mt-12 mx-1 clickable" @click="$goTo('/portfolio', 'portfolio')">  {{ $ml.get('landing_view_portfolio') }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
                  <v-flex sm12>
                      <v-layout class="mt-1">
                        <v-img v-for="(social, i) in social_media" :key="i" :src="social.image" :lazy-src="social.image" max-width="30px" class="mx-1 img-btn clickable" @click="openLink(social.url)" />
                      </v-layout>
                    </v-flex>
              </v-flex>
              <v-flex sm5 mt-12>
                <h3 class="banner-title white--text mb-8" >{{ $ml.get('landing_title') }} </h3>
                <p class="avenir font-18 white--text mb-0">  {{ $ml.get('landing_subtitle') }}</p>
                <v-btn class="btn-text btn-header max-width-200 my-8" depressed @click="$goTo('/about', 'about_us')"> 
                  {{ $ml.get('button_know_more') }} 
                  <v-img :src="right_arrow" :lazy-src="right_arrow" style="width:18px; cursor:pointer" class="ml-2" ></v-img>
                </v-btn>
              </v-flex>
              </v-layout>
            </div>
        </v-layout>

         
        <v-layout row wrap align-center justify-end class="white-background ">
              <v-flex sm3 xs12>
                <v-layout align-center>
                  <v-img :src="about" :lazy-src="about" height="280px"/>
                </v-layout>
              </v-flex>
              
              <v-flex sm3 xs12 class="hover-title" @click="$goTo('/about', 'about')">
                <div class="mx-8">
                  <h3 class="antenna font-23 my-4 primary-color"> {{ $ml.get('header_menu_about') }} </h3>
                  <p class="avenir font-15 primary-color" :class="{'font-14': $vuetify.breakpoint.mdAndDown}">{{$ml.get('landing_about_us_text')}}</p>
                </div>
              </v-flex>

              <v-flex sm3 xs12>
                <v-layout align-center>
                  <v-img :src="business" :lazy-src="business" height="280px"/>
                </v-layout>
              </v-flex>
              
              <v-flex sm3 xs12 class="hover-title" @click="$goTo('/solutions', 'business_solutions')">
                <div class="mx-8">
                  <h3 class="antenna font-23 my-4 primary-color"> {{ $ml.get('header_menu_business_solutions') }} </h3>
                  <p class="avenir font-15 primary-color" :class="{'font-14': $vuetify.breakpoint.mdAndDown}">{{$ml.get('landing_business_text')}}</p>
                </div>
              </v-flex>
        </v-layout>

       
    </v-layout>
  </v-container>
</template>

<script>

export default {
  name: 'Landing',
  data () {
    return {
      about: require('../assets/img/images/About-us-home.png'),
      business: require('../assets/img/images/Business-solutions-home.png'),
      driveop: require('../assets/img/brand/driveop2.png'),
      right_arrow: require('../assets/img/brand/driveop_arrow_right.png'),
      social_media: {
        linkedin: {
          image: require('../assets/img/icons/linkedin2.png'),
          event: 'contact_goto_twitter',
          url: 'https://www.linkedin.com/company/drive-op/'
        },
        facebook: {
          image: require('../assets/img/icons/facebook2.png'),
          event: 'contact_goto_facebook',
          url: 'https://www.facebook.com/DriveOpInternational/'
        },
        instagram: {
          image: require('../assets/img/icons/instagram2.png'),
          event: 'contact_goto_instagram',
          url: 'https://www.instagram.com/drive.op/'
        },
        cb: {
          image: require('../assets/img/icons/cb2.png'),
          event: 'contact_goto_instagram',
          url: 'https://www.crunchbase.com/organization/driveop'
        },
      },
    }
  },
  methods: {
    openLink (url) {
      window.open(url, '_blank')
    }
  }
}
</script>

<style scoped>
  .second-banner {
    background: rgb(130,224,221) !important;
    background: linear-gradient(90deg, rgba(130,224,221,1) 0%, rgba(88,212,209,1) 100%) !important;
  }
  .theme--light.v-btn.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
  background-color:#028b8f !important; 
}

</style>
